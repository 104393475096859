@import "../../node_modules/bootstrap/scss/bootstrap";
@import "https://unpkg.com/open-props";

body {
  padding: 0;
  background-color: #fff;
  font-size: var(--font-size-fluid-1);
  margin: 0;
}

header {
  background-image: url(../images/heizlast_header.jpg);
  background-position: center;
  background-size: cover;
  height: var(--size-fluid-10);

  h1,
  h3 {
    color: #fff;
  }

  .wrapper {
    padding-top: var(--size-10);
  }
}

main {
  margin-block: var(--size-10);
}

.formItem {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--size-3);
}

.input {
  --_radius: 8px;
  display: grid;
  grid-template-columns: 1fr min-content;
  width: 380px;

  input {
    border-radius: var(--_radius) 0 0 var(--_radius);
    text-align: right;
    padding-right: 1ch;
    background-color: var(--gray-2);
    border: none;
    width: 100%;
  }

  .einheit {
    background-color: var(--green-4);
    border-radius: 0 var(--_radius) var(--_radius) 0;
    color: #fff;
    display: flex;
    width: min-content;
    padding-inline: 1ch;
    border: 1px solid var(--green-4);
  }

  .info {
    background-color: var(--green-4);
    color: #fff;
    width: min-content;
    padding-inline: 1ch;
    display: flex;
    font-weight: 500;
    border-right: 1px solid #fff;
    cursor: pointer;
  }
}

.info .input {
  display: grid;
  grid-template-columns: 1fr min-content min-content;
}

input#personen {
  width: 4ch;
  place-self: end;
}

input[readonly] {
  background-color: var(--green-1);
}

.container {
  margin-inline: auto;
  width: min(96%, 1000px);
}

input {
  font-size: var(--font-size-fluid-1);
}


h1 {
  font-size: var(--font-size-fluid-3);
  text-align: left;
}

h3 {
  font-size: var(--font-size-fluid-1);
  font-weight: var(--font-weight-4);
  text-align: left;
}

header {
  h3 {
    padding: 0;
    margin: 0;
    text-align: left;
  }

  h1 {
    margin-top: 0;
    text-align: left;
  }
}

.information {
  padding: var(--size-5);
  background-color: var(--gray-2);
  margin-block: var(--size-6);
}

.heizungsart {
  .btns {
    display: flex;
    justify-content: space-between;
    gap: var(--size-2);

    .btn-check+.btn {
      --bs-btn-active-bg: var(--green-8);
      --bs-btn-bg: var(--green-5);
    }

  }

}

.tooltip {

  /* If you want dots under the hoverable text */
}



/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
  visibility: visible;
}

.tip {
  --tooltip-bg: var(--green-9);
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;

  .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 25%;
    left: 100%;
    /* To the right of the tooltip */
    margin-top: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent transparent var(--tooltip-bg);
  }

  .tooltiptext {
    font-size: var(--font-size-1);
    visibility: hidden;
    color: #fff;
    text-align: center;
    z-index: 1;
    opacity: 0;
    background-color: var(--tooltip-bg);
    border-radius: var(--radius-conditional-2);
    width: 50ch;
    padding: var(--size-2);
    transition: opacity 1s;
    position: absolute;
    top: -5px;
    right: 105%;
  }
}

.tip:hover {
  .tooltiptext {
    visibility: visible;
    opacity: 1;
  }
}